<template>
    <div class="container-login">
        <div class="card-login">
            <v-row>
                <v-col cols="12" class="text-center">
                    <img src="@/assets/das_400.jpg" alt="" width="250" class="mb-5">
                    <h2>REGISTRATE SYSTEEM</h2>
                    <v-text-field type="text" class="input-control"  prepend-icon="mdi-email"  label="Email" v-model="loginModel.username"></v-text-field>
                    <v-text-field type="password"  class="input-control" prepend-icon="mdi-lock" label="Password" v-model="loginModel.password" v-on:keyup.enter="LoginSession"></v-text-field>
                    <!-- <v-btn dark color="primary" block @click="LoginSession"  :disabled="loading">
                        <span v-if="!loading">Sign in</span>
                        <span v-if="loading">Please wait...</span>
                        <v-progress-circular
                            class="ml-2"
                            v-if="loading"
                            :width="2"
                            :size="15"
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                    </v-btn> -->
                    <br>
                    <v-btn class="primary mt-10">Log in</v-btn>
                    <br>

                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
          
          loginModel:{
            username: '',
            password:''
          },
          
            dataLogin:{
                username:'',
                password:'',
            },
        }
    },

    computed:{
        
    },

    methods:{

        
    }
}
</script>

<style lang="scss">
.container-login{
    width: 100%;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    .card-login{
        width: 600px;
        height: 520px;
        background-color: #fff;
        padding: 40px;
        box-sizing: border-box;
        border-radius: 0px;
        box-shadow: 0px 3px 5px 3px #d3d3d3;
        .input-control{
            color: #1976d2 !important;
        }
    }
    label{
        color: #1976d2!important;
    }
    .theme--light.v-input input, .theme--light.v-input textarea {
        color: #fff !important;
         border-bottom:1px solid #fff !important
    }
    .v-input--selection-controls__input {
        height: 18px !important;
        width: 17px !important;
        background-color: #fff !important;
    }
}
.fade-enter-from,
    .fade-leave-to{
        opacity: 0;
    }

    .fade-enter-active,
    .fade-leave-active{
        transition: opacity 0.1s ease-out;
    }
</style>